var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.cellData.visible)?_c('li',{staticClass:"kalendar-cell",class:{
        selected: _vm.selected,
        'is-an-hour': _vm.isAnHour(_vm.index),
        'has-events': _vm.cell_events && _vm.cell_events.length > 0,
        'being-created': !!_vm.being_created || _vm.hasPopups,
    },style:(`
  height: ${_vm.kalendar_options.cell_height}px;
`),on:{"mouseover":function($event){if($event.target !== $event.currentTarget)return null;return _vm.mouseMove()},"mousedown":function($event){if($event.target !== $event.currentTarget)return null;return _vm.mouseDown()},"mouseup":function($event){return _vm.mouseUp()}}},_vm._l((_vm.cell_events),function(event,eventIndex){return (_vm.cell_events && _vm.cell_events.length)?_c('KalendarEvent',{key:eventIndex,style:(`z-index: 10`),attrs:{"event":event,"total":_vm.cell_events.length,"index":eventIndex,"overlaps":_vm.overlapValue}}):_vm._e()}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }